import {
  Grid,
  Typography,
  Button,
  Card,
  Box,
  CardActions,
  TextField,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useAppDispatch, useAppSelector} from "../../app/store/configureStore";
import {useEffect, useState} from "react";
import agent from "../../app/api/agent";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import {RfpResponse} from "../../app/models/rfpResponse";
import {toast} from "react-toastify";
import RfpAdditionalDocumentsListCard from "../../app/components/RfpAdditionalDocumentsListCard";
import {
  openCreateChat,
  openMessageCentar,
} from "../messageCenter/messageCenterSlice";

const RequestForProposalResponse = () => {
  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const {id} = useParams<{id: string}>();
  const [rfpResponseDocuments, setRfpResponseDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rfpResponse, setRfpResponse] = useState<RfpResponse>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      setLoading(true);
      agent.RFPResponses.oneResponse(parseInt(id))
        .then((response) => {
          console.log(response);
          setRfpResponse(response);
          setRfpResponseDocuments(response.documents.$values);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [id]);

  async function handleResponse(id: string | undefined) {
    if (id) {
      setLoading(true);
      agent.RFPResponses.update(parseInt(id))
        .then(() => {
          toast.success("Offer was successfully accepted");
          navigate("/deals");
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }

  const handleInquiry = () => {
    dispatch(openMessageCentar());
    dispatch(openCreateChat());

    toast.warning(
      "Create new chat to Inquire. Use the Email Address of the User"
    );
  };

  async function handleNavigate() {
    navigate(-1);
  }

  if (!user) {
    navigate("/login");
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          height: "100%",
          width: "100%",
          padding: "20px",
          paddingTop: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
          }}
        >
          <Box onClick={() => handleNavigate()}>
            <ArrowBackIosNewIcon
              sx={{
                display: "flex",
                color: "rgba(112, 112, 112, 1)",
                "&:hover": {
                  color: "rgba(208, 235, 238, 1)",
                },
              }}
            />
          </Box>
        </Box>
        <form>
          <Card
            sx={{
              padding: "20px",
              height: "100%",
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                paddingLeft: "25px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Roboto",
                  color: "#424242",
                  fontWeight: "600",
                  fontSize: "23px",
                }}
              >
                {rfpResponse?.role.replace(/([A-Z])/g, " $1").trim()} Service
                Offer
              </Typography>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              <Grid item xs={6}>
                <TextField
                  label="First Name"
                  type="text"
                  name="FirstName"
                  fullWidth
                  variant="standard"
                  value={rfpResponse?.firstName}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Last Name"
                  type="text"
                  name="LastName"
                  fullWidth
                  variant="standard"
                  value={rfpResponse?.lastName}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Email Address"
                  type="text"
                  name="EmailAddress"
                  fullWidth
                  variant="standard"
                  value={rfpResponse?.emailAddress}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Phone Number"
                  type="text"
                  name="PhoneNumber"
                  fullWidth
                  variant="standard"
                  className="no-spinners"
                  value={rfpResponse?.phoneNumber}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Office Number"
                  type="text"
                  name="OfficeNumber"
                  fullWidth
                  variant="standard"
                  className="no-spinners"
                  value={rfpResponse?.officeNumber}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Company Name"
                  type="text"
                  name="CompanyName"
                  fullWidth
                  variant="standard"
                  value={rfpResponse?.companyName}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Company Type"
                  type="text"
                  name="CompanyType"
                  fullWidth
                  variant="standard"
                  value={rfpResponse?.companyType}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Company Address"
                  type="text"
                  name="CompanyAddress"
                  fullWidth
                  variant="standard"
                  value={rfpResponse?.companyAddress}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Unit"
                  type="text"
                  name="Unit"
                  fullWidth
                  variant="standard"
                  value={rfpResponse?.unit}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="City"
                  name="City"
                  fullWidth
                  variant="standard"
                  value={rfpResponse?.city}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Zip Code"
                  type="number"
                  name="ZipCode"
                  fullWidth
                  variant="standard"
                  className="no-spinners"
                  value={rfpResponse?.zipCode}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="State"
                  name="State"
                  fullWidth
                  variant="standard"
                  value={rfpResponse?.state}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Country"
                  name="Country"
                  fullWidth
                  variant="standard"
                  value={rfpResponse?.country}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "20px",
                paddingRight: "20px",
                height: "100%",
                marginBottom: "80px",
              }}
            >
              <Grid item xs={12}>
                <TextField
                  label="For Whom"
                  name="ForWhom"
                  value={rfpResponse?.forWhom}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  fullWidth
                  variant="standard"
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Time required to complete"
                  name="TimeRequired"
                  type="text"
                  value={rfpResponse?.timeRequired}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  fullWidth
                  variant="standard"
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Cost (USD)"
                  type="text"
                  fullWidth
                  variant="standard"
                  name="Cost"
                  disabled
                  value={rfpResponse?.cost}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Scope of Work"
                  type="text"
                  name="ScopeOfWork"
                  fullWidth
                  variant="standard"
                  disabled
                  value={rfpResponse?.scopeOfWork}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{fontWeight: "700", paddingBottom: "20px"}}>
                  Attach proposal
                </Typography>
                {rfpResponseDocuments && rfpResponseDocuments.length > 0 ? (
                  <Card sx={{backgroundColor: "#F7F7F7", padding: 1}}>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CardActions
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <RfpAdditionalDocumentsListCard
                          rfpDocuments={rfpResponseDocuments}
                          name="x"
                        ></RfpAdditionalDocumentsListCard>
                      </CardActions>
                    </CardActions>
                  </Card>
                ) : (
                  <Card
                    sx={{
                      backgroundColor: "#F7F7F7",
                      padding: 1,
                      height: "300px",
                      width: "470px",
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    No documents attached
                  </Card>
                )}
              </Grid>
            </Grid>
          </Card>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              position: "sticky",
              backgroundColor: "#F5F5F5",
              bottom: 0,
            }}
          >
            <Button
              onClick={() => handleResponse(id)}
              sx={{
                background: "rgba(4, 119, 130, 1)",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "rgba(1, 150, 164, 1)",
                },
                "&:disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              type="submit"
            >
              Accept offer
            </Button>

            <Button
              onClick={() => handleInquiry()}
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
            >
              Inquire
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default RequestForProposalResponse;
