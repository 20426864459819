import {
  Grid,
  Typography,
  Button,
  Card,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@mui/material";
import {Form, useNavigate, useParams} from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {useAppDispatch, useAppSelector} from "../../app/store/configureStore";
import {Controller, FieldValues, useForm} from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInputCustom";
import AppSelectList from "../../app/components/AppSelectListCustom";
import {useEffect, useState} from "react";
import agent from "../../app/api/agent";
import {toast} from "react-toastify";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import RfpAdditionalDocumentsListCard from "../../app/components/RfpAdditionalDocumentsListCard";
import {TransitionProps} from "@mui/material/transitions";
import React from "react";
import {setOpenCardDrawerWithId} from "../messageCenter/messageCenterSlice";

interface Image {
  id: number;
  imageFamilyId: number;
  fileName: string;
  imageUrl: string;
}

const OfferPageProperty = () => {
  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const {id, contractProgressId} = useParams<{
    id: string;
    contractProgressId: string;
  }>();
  const {control, handleSubmit} = useForm();
  const [property, setProperty] = useState({
    id: 0,
  });
  const [contractProgressDocuments, setContractProgressDocuments] = useState(
    []
  );
  const [contractProgressImages, setContractProgressImages] = useState([]);
  const [openModalImage, setOpenModalImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const dispatch = useAppDispatch();

  async function handleSubmitData(data: FieldValues) {
    setLoading(true);
    data.PropertyId = property.id;
    data.ContractId = contractProgressId;

    try {
      agent.OfferProperty.create(data).then(() => {
        toast.success("Offer was sent successfully");
        navigate("/deals");
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      agent.Marketplace.details(parseInt(id))
        .then((response) => {
          setProperty(response);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [id]);

  useEffect(() => {
    if (contractProgressId) {
      agent.Document.DocumentsForContractProgressBuyerSide(
        parseInt(contractProgressId)
      )
        .then((response) => {
          setContractProgressDocuments(response.$values);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [contractProgressId]);

  useEffect(() => {
    contractProgressId &&
      agent.Document.ImagesForContractProgressBuyerSide(
        parseInt(contractProgressId)
      )
        .then((response) => {
          setContractProgressImages(response.$values);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
  }, [contractProgressId]);

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ImageModal = (props: any) => {
    const handleClose = () => {
      setOpenModalImage(false);
      setSelectedImage(null);
    };

    return (
      <div>
        <Dialog
          open={openModalImage}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            {selectedImage && (
              <Box>
                <img
                  src={selectedImage.imageUrl}
                  alt={selectedImage.fileName}
                  style={{width: "100%", height: "auto"}}
                />
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const handleOpenModalImage = (image: any) => {
    setSelectedImage(image);
    setOpenModalImage(true);
  };

  async function cancelEdit() {
    navigate(-1);
    if (contractProgressId) {
      dispatch(setOpenCardDrawerWithId(parseInt(contractProgressId)));
    }
  }

  if (!user) {
    navigate("/login");
  }

  const mortgage = ["Cash Deal", "Mortgage"];
  const preApproval = ["Yes", "No"];

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F4F4F4",
          height: "100%",
          width: "100%",
          paddingX: "20px",
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            top: 0,
            height: "60px",
            zIndex: "10",
            overflowY: "scroll",
          }}
        >
          <ArrowBackIosNewIcon
            onClick={cancelEdit}
            sx={{
              display: "flex",
              color: "rgba(112, 112, 112, 1)",
              "&:hover": {
                color: "rgba(208, 235, 238, 1)",
              },
            }}
          />
        </Box>

        <Form onSubmit={handleSubmit(handleSubmitData)}>
          <Card
            sx={{
              padding: "20px",
              height: "100%",
              overflowY: "scroll",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                paddingLeft: "25px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Roboto",
                  color: "#424242",
                  fontWeight: "600",
                  fontSize: "23px",
                }}
              >
                Offer Submission
              </Typography>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              <Grid item xs={6} sx={{display: "none"}}>
                <AppTextInput
                  control={control}
                  label="PropertyId"
                  name="PropertyId"
                  value={property.id}
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Name of Broker"
                  type="text"
                  name="nameOfBroker"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Name of Buyer"
                  type="text"
                  name="nameOfBuyer"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Address of Buyer/Firm"
                  type="text"
                  name="addressOfBuyerFirm"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Email Address"
                  type="text"
                  name="emailAddress"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  control={control}
                  label="Offer Price"
                  type="text"
                  name="offerPrice"
                  required
                />
              </Grid>

              <Grid item xs={6} sx={{paddingBottom: "30px"}}>
                <AppTextInput
                  control={control}
                  label="Acceleration Option"
                  type="text"
                  name="acceleratedOption"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{fontWeight: "700"}}>Contingencies</Typography>
              </Grid>

              <Grid item xs={6}>
                <FormGroup>
                  <Box>
                    <Controller
                      control={control}
                      name="AbilityToSecureFinancing"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Ability to secure financing"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="AppraisalValue"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Appraisal Value"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="SatisfactoryTitle"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Satisfactory title"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="PropertySurvey"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Property survey"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="InspectionContingency"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Inspection contingency"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="ReviewOfLeases"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Review of leases"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="LandUseApproval"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Sale Contingency"
                        />
                      )}
                    />
                  </Box>
                </FormGroup>
              </Grid>

              <Grid item xs={6}>
                <FormGroup>
                  <Box>
                    <Controller
                      control={control}
                      name="PropertyIncomeAndExpense"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Property income and expense"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="EnvironmentalConditions"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Environmental conditions"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="SaleOfOtherProperty"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Sale of other Property"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="InsuranceApproval"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Inspection Contingency"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="ClosingDate"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Closing Date"
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="Other (please specify below):"
                      defaultValue={false}
                      render={({field: {onChange, value}}) => (
                        <FormControlLabel
                          control={
                            <Checkbox checked={value} onChange={onChange} />
                          }
                          label="Other (please specify below):"
                        />
                      )}
                    />
                  </Box>
                  <AppTextInput
                    control={control}
                    label="Other"
                    type="text"
                    name="other"
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                width: "50%",
                paddingLeft: "20px",
                paddingRight: "20px",
                height: "100%",
              }}
            >
              <Grid item xs={12}>
                <AppTextInput
                  control={control}
                  label="Earnest Money Amount and Paid"
                  type="text"
                  name="earnestMoneyAmountAndPaid"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <AppSelectList
                  control={control}
                  items={mortgage}
                  label="Option to Purchase"
                  name="mortgage"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <AppSelectList
                  control={control}
                  items={preApproval}
                  label="MGT Pre-approval"
                  name="preApproval"
                  required
                />
              </Grid>

              <Grid item xs={12} marginBottom="10px">
                <Typography sx={{fontWeight: "700", paddingBottom: "20px"}}>
                  Attach documents
                </Typography>
                <Card
                  sx={{
                    backgroundColor: "#F7F7F7",
                    padding: 1,
                    display: "flex",
                  }}
                >
                  <FormControl>
                    <RfpAdditionalDocumentsListCard
                      rfpDocuments={contractProgressDocuments}
                      control={control}
                      name="mainDocs"
                    />
                  </FormControl>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{fontWeight: "700", paddingBottom: "20px"}}>
                  Attach images
                </Typography>
                <Card
                  sx={{
                    backgroundColor: "#F7F7F7",
                    padding: 1,
                    display: "flex",
                  }}
                >
                  <FormControl>
                    {contractProgressImages && contractProgressImages.length > 0
                      ? contractProgressImages.map((image: any) => (
                          <React.Fragment key={image.id}>
                            <ImageModal
                              setOpen={setOpenModalImage}
                              open={openModalImage}
                            />
                            <Grid item>
                              <Typography
                                variant="h6"
                                gutterBottom
                                sx={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  width: "200px",
                                  whiteSpace: "nowrap",
                                  fontSize: "14px",
                                }}
                              >
                                {image.fileName}
                              </Typography>
                              <img
                                src={image.imageUrl}
                                alt=""
                                style={{
                                  height: 260,
                                  width: 200,
                                  paddingTop: 2.5,
                                  paddingBottom: 2.5,
                                  paddingLeft: 3,
                                  paddingRight: 3,
                                  cursor: "pointer",
                                }}
                                onClick={() => handleOpenModalImage(image)}
                              />
                            </Grid>
                          </React.Fragment>
                        ))
                      : "There are no images uploaded for the Offer Form"}
                  </FormControl>
                </Card>
              </Grid>
            </Grid>
          </Card>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              position: "sticky",
              backgroundColor: "#F5F5F5",
              bottom: 0,
            }}
          >
            <Button
              sx={{
                background: "#047782",
                "&:hover": {
                  background: "#0196A4",
                },
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#fff",
                borderRadius: "4px",
                letterSpacing: "1.25px",
                margin: "15px",
              }}
              type="submit"
            >
              Submit
            </Button>

            <Button
              onClick={cancelEdit}
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
            >
              Cancel
            </Button>
          </Box>
        </Form>
      </Box>
    </>
  );
};

export default OfferPageProperty;
