import {FormControl, MenuItem, FormHelperText, TextField} from "@mui/material";
import {useController, UseControllerProps} from "react-hook-form";

interface Props extends UseControllerProps {
  label: string;
  items: string[];
  value?: any;
  required?: boolean;
}

var UsaStates = require("usa-states").UsaStates;
var usStates = new UsaStates();

export default function AppSelectListStates(props: Props) {
  const {fieldState, field} = useController({...props, defaultValue: ""});
  return (
    <FormControl fullWidth error={!!fieldState.error}>
      <TextField
        select
        value={field.value}
        label={props.label}
        onChange={field.onChange}
        variant="standard"
      >
        {usStates.states.map((option: any) => (
          <MenuItem key={option.name} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  );
}
