import {styled, Theme, CSSObject} from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import {Button} from "@mui/material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import {NavLink} from "react-router-dom";
import StorefrontIcon from "@mui/icons-material/Storefront";
import {useState} from "react";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import BookmarksRoundedIcon from "@mui/icons-material/BookmarksRounded";
import PeopleIcon from "@mui/icons-material/People";
import WorkIcon from "@mui/icons-material/Work";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import {useAppSelector} from "../store/configureStore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const [open, setOpen] = useState(true);

  const {user} = useAppSelector((state) => state.account);

  const handleDrawerState = () => {
    setOpen((prevState) => !prevState);
  };

  const [showActionMarketplace, setShowActionMarketplace] = useState(false);
  const [showActionDashboard, setShowActionDashboard] = useState(false);
  const [showActionDeals, setShowActionDeals] = useState(false);
  const [showActionNetwork, setShowActionNetwork] = useState(false);
  const [showActionLibrary, setShowActionLibrary] = useState(false);
  const [showActionReports, setShowActionReports] = useState(false);
  const [showActionStatistics, setShowActionStatistics] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);

  const setAllFalse = () => {
    setShowActionMarketplace(false);
    setShowActionDashboard(false);
    setShowActionDeals(false);
    setShowActionNetwork(false);
    setShowActionLibrary(false);
    setShowActionReports(false);
    setShowActionStatistics(false);
  };

  const handleMarketplaceClick = () => {
    setShowSubMenu((prevState) => !prevState);
    setAllFalse();
  };

  return (
    <Box sx={{display: "flex", alignItems: "baseline"}}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        sx={{height: "calc(100vh - 70px)"}}
      >
        <List sx={{textAlign: "center", top: "60px", width: "240px"}}>
          <ListItem disableGutters sx={{display: "block"}}>
            <Button
              sx={{
                minHeight: 48,
                px: 2.5,
                textTransform: "unset",
                width: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <MenuIcon onClick={handleDrawerState} sx={{color: "#0D2561"}} />
              <ListItemText
                onClick={handleDrawerState}
                sx={{
                  opacity: open ? 1 : 0,
                  color: "#0D2561",
                  textDecoration: "none!important",
                  textAlign: "left",
                }}
                primary="&nbsp;&nbsp;Menu"
              />
            </Button>
          </ListItem>

          <Box sx={{marginTop: "25px"}}></Box>

          <NavLink
            to="/marketplace"
            style={{textDecoration: "none"}}
            onClick={handleMarketplaceClick}
          >
            {({isActive}) =>
              isActive ? (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <StorefrontRoundedIcon sx={{color: "#0D2561"}} />

                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;Marketplace"
                    />
                    {showSubMenu ? (
                      <KeyboardArrowUpIcon sx={{color: "#0D2561"}} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{color: "#0D2561"}} />
                    )}
                  </Button>
                </ListItem>
              ) : (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onMouseEnter={() => setShowActionMarketplace(true)}
                  onMouseLeave={() => setShowActionMarketplace(false)}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {showActionMarketplace === true ? (
                      <StorefrontRoundedIcon sx={{color: "#0D2561"}} />
                    ) : (
                      <StorefrontIcon sx={{color: "#0D2561"}} />
                    )}
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;Marketplace"
                    />
                  </Button>
                </ListItem>
              )
            }
          </NavLink>

          {showSubMenu && (
            <div style={{paddingLeft: "20px"}}>
              <NavLink
                to="/marketplace/my-marketplace"
                style={{textDecoration: "none"}}
              >
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "5px",
                    paddingTop: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    sx={{
                      minHeight: 36,
                      px: 2,
                      textTransform: "unset",
                      width: "100%",
                    }}
                  >
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textAlign: "left",
                      }}
                      primary="My Postings"
                    />
                  </Button>
                </ListItem>
              </NavLink>
              <NavLink
                to="/marketplace/my-offers"
                style={{textDecoration: "none"}}
              >
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "5px",
                    paddingTop: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Button
                    sx={{
                      minHeight: 36,
                      px: 2,
                      textTransform: "unset",
                      width: "100%",
                    }}
                  >
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textAlign: "left",
                      }}
                      primary="My Offers"
                    />
                  </Button>
                </ListItem>
              </NavLink>
            </div>
          )}

          <NavLink
            to="/dashboard"
            style={{textDecoration: "none"}}
            onClick={() => setAllFalse()}
          >
            {({isActive}) =>
              isActive ? (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <DashboardIcon sx={{color: "#0D2561"}} />

                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;Dashboard"
                    />
                  </Button>
                </ListItem>
              ) : (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onMouseEnter={() => setShowActionDashboard(true)}
                  onMouseLeave={() => setShowActionDashboard(false)}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {showActionDashboard === true ? (
                      <DashboardIcon sx={{color: "#0D2561"}} />
                    ) : (
                      <DashboardOutlinedIcon sx={{color: "#0D2561"}} />
                    )}
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;Dashboard"
                    />
                  </Button>
                </ListItem>
              )
            }
          </NavLink>

          <NavLink
            to="/deals"
            style={{textDecoration: "none"}}
            onClick={() => setAllFalse()}
          >
            {({isActive}) =>
              isActive ? (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <WorkIcon sx={{color: "#0D2561"}} />

                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;My Deals"
                    />
                  </Button>
                </ListItem>
              ) : (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onMouseEnter={() => setShowActionDeals(true)}
                  onMouseLeave={() => setShowActionDeals(false)}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {showActionDeals === true ? (
                      <WorkIcon sx={{color: "#0D2561"}} />
                    ) : (
                      <WorkOutlineIcon sx={{color: "#0D2561"}} />
                    )}
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;My Deals"
                    />
                  </Button>
                </ListItem>
              )
            }
          </NavLink>

          <NavLink
            to="/add-partners"
            style={{textDecoration: "none"}}
            onClick={() => setAllFalse()}
          >
            {({isActive}) =>
              isActive ? (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <PeopleIcon sx={{color: "#0D2561"}} />

                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;My Network"
                    />
                  </Button>
                </ListItem>
              ) : (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onMouseEnter={() => setShowActionNetwork(true)}
                  onMouseLeave={() => setShowActionNetwork(false)}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {showActionNetwork === true ? (
                      <PeopleIcon sx={{color: "#0D2561"}} />
                    ) : (
                      <PeopleOutlineOutlinedIcon sx={{color: "#0D2561"}} />
                    )}
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;My Network"
                    />
                  </Button>
                </ListItem>
              )
            }
          </NavLink>

          <NavLink
            to="/library"
            style={{textDecoration: "none"}}
            onClick={() => setAllFalse()}
          >
            {({isActive}) =>
              isActive ? (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <BookmarksRoundedIcon sx={{color: "#0D2561"}} />

                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;My Library"
                    />
                  </Button>
                </ListItem>
              ) : (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onMouseEnter={() => setShowActionLibrary(true)}
                  onMouseLeave={() => setShowActionLibrary(false)}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {showActionLibrary === true ? (
                      <BookmarksRoundedIcon sx={{color: "#0D2561"}} />
                    ) : (
                      <BookmarksOutlinedIcon sx={{color: "#0D2561"}} />
                    )}
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;My Library"
                    />
                  </Button>
                </ListItem>
              )
            }
          </NavLink>

          <NavLink
            to="/reports"
            style={{textDecoration: "none"}}
            onClick={() => setAllFalse()}
          >
            {({isActive}) =>
              isActive ? (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <AssessmentRoundedIcon sx={{color: "#0D2561"}} />

                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;My Reports"
                    />
                  </Button>
                </ListItem>
              ) : (
                <ListItem
                  disableGutters
                  sx={{
                    display: "block",
                    paddingBottom: "0",
                    paddingTop: "0",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  onMouseEnter={() => setShowActionReports(true)}
                  onMouseLeave={() => setShowActionReports(false)}
                >
                  <Button
                    sx={{
                      minHeight: 48,
                      px: 2.5,
                      textTransform: "unset",
                      width: "100%",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    {showActionReports === true ? (
                      <AssessmentRoundedIcon sx={{color: "#0D2561"}} />
                    ) : (
                      <AssessmentOutlinedIcon sx={{color: "#0D2561"}} />
                    )}
                    <ListItemText
                      sx={{
                        opacity: open ? 1 : 0,
                        color: "#0D2561",
                        textDecoration: "none!important",
                        textAlign: "left",
                      }}
                      primary="&nbsp;&nbsp;My Reports"
                    />
                  </Button>
                </ListItem>
              )
            }
          </NavLink>
          {user?.profession === "Admin" ? (
            <NavLink
              to="/statistics"
              style={{textDecoration: "none"}}
              onClick={() => setAllFalse()}
            >
              {({isActive}) =>
                isActive ? (
                  <ListItem
                    disableGutters
                    sx={{
                      display: "block",
                      paddingBottom: "0",
                      paddingTop: "0",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      sx={{
                        minHeight: 48,
                        px: 2.5,
                        textTransform: "unset",
                        width: "100%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <AnalyticsRoundedIcon sx={{color: "#0D2561"}} />

                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "#0D2561",
                          textDecoration: "none!important",
                          textAlign: "left",
                        }}
                        primary="&nbsp;&nbsp;Statistics"
                      />
                    </Button>
                  </ListItem>
                ) : (
                  <ListItem
                    disableGutters
                    sx={{
                      display: "block",
                      paddingBottom: "0",
                      paddingTop: "0",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    onMouseEnter={() => setShowActionStatistics(true)}
                    onMouseLeave={() => setShowActionStatistics(false)}
                  >
                    <Button
                      sx={{
                        minHeight: 48,
                        px: 2.5,
                        textTransform: "unset",
                        width: "100%",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      {showActionStatistics === true ? (
                        <AnalyticsRoundedIcon sx={{color: "#0D2561"}} />
                      ) : (
                        <AnalyticsOutlinedIcon sx={{color: "#0D2561"}} />
                      )}
                      <ListItemText
                        sx={{
                          opacity: open ? 1 : 0,
                          color: "#0D2561",
                          textDecoration: "none!important",
                          textAlign: "left",
                        }}
                        primary="&nbsp;&nbsp;Statistics"
                      />
                    </Button>
                  </ListItem>
                )
              }
            </NavLink>
          ) : null}
        </List>
      </Drawer>
    </Box>
  );
}
