import {Typography, Paper, Box} from "@mui/material";
import {HtmlDocument} from "../../../app/models/htmlDocument";
// @ts-ignore
import ReactHtmlParser from "react-html-parser";
import {useAppSelector} from "../../../app/store/configureStore";

interface Props {
  document: HtmlDocument;
  onDocumentSelect: (id: number) => void;
  selectDocumentWithIdOneClick: number | undefined;
  onSelectDocumentOneClick: (documentId?: number) => void;
  representativeRoleInContractProgress: undefined;
}

export default function DocumentCard({
  document,
  onDocumentSelect,
  selectDocumentWithIdOneClick,
  onSelectDocumentOneClick,
  representativeRoleInContractProgress,
}: Props) {
  const {user} = useAppSelector((state) => state.account);

  return (
    <div
      onDoubleClick={() => onDocumentSelect(document.id)}
      onClick={() => onSelectDocumentOneClick(document.id)}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "200px",
          whiteSpace: "nowrap",
        }}
      >
        {document.title}
      </Typography>
      {document.id === selectDocumentWithIdOneClick ? (
        <Paper
          elevation={4}
          sx={{
            width: 200,
            height: 260,
            paddingX: 2.5,
            paddingY: 3,
            fontSize: "4px",
            border: "2px solid red",
          }}
        >
          <Box
            sx={{
              userSelect: "none",
              wordWrap: "break-word",
              overflow: "hidden",
              height: 212,
            }}
          >
            {ReactHtmlParser(document.text)}
          </Box>
          {(user?.profession === "Buyer" &&
            document.isSignedByBuyer === true) ||
          (user?.profession === "Seller" && document.isSignedBySeller) ? (
            <>
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "800",
                  color: "green",
                  border: "1px solid black",
                  transform: "translateY(-20px) translateX(5px) rotate(27deg)",
                  position: "relative",
                  top: "-125px",
                }}
              >
                APPROVED <br />
                and SIGNED
              </div>
            </>
          ) : (user?.profession === "Buyer" &&
              document.isApprovedByBuyer === true) ||
            (user?.profession === "Seller" &&
              document.isApprovedBySeller === true) ||
            (representativeRoleInContractProgress === "BuyerRepresentative" &&
              document.isApprovedByBuyerRepresentative === true) ||
            (representativeRoleInContractProgress === "SellerRepresentative" &&
              document.isApprovedBySellerRepresentative === true) ||
            (representativeRoleInContractProgress === "BuyerAttorney" &&
              document.isApprovedByBuyerAttorney === true) ||
            (representativeRoleInContractProgress === "SellerAttorney" &&
              document.isApprovedBySellerAttorney === true) ||
            (user?.profession === "Lender" &&
              document.isApprovedByLender === true) ||
            (user?.profession === "TitleAndSettlement" &&
              document.isApprovedBySettlementAgent === true) ||
            (user?.profession === "PropertyInsurance" &&
              document.isApprovedByPropertyInsurance === true) ? (
            <div
              style={{
                fontSize: "30px",
                fontWeight: "800",
                color: "green",
                border: "1px solid black",
                transform: "translateY(-20px) translateX(5px) rotate(27deg)",
                position: "relative",
                top: "-95px",
              }}
            >
              APPROVED
            </div>
          ) : null}
        </Paper>
      ) : (
        <Paper
          elevation={4}
          sx={{
            width: 200,
            height: 260,
            paddingX: 2.5,
            paddingY: 3,
            fontSize: "4px",
          }}
        >
          <Box
            sx={{
              userSelect: "none",
              wordWrap: "break-word",
              overflow: "hidden",
              height: 212,
            }}
          >
            {ReactHtmlParser(document.text)}
          </Box>
          {(user?.profession === "Buyer" &&
            document.isSignedByBuyer === true) ||
          (user?.profession === "Seller" &&
            document.isSignedBySeller === true) ? (
            <>
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "800",
                  color: "green",
                  border: "1px solid black",
                  transform: "translateY(-20px) translateX(5px) rotate(27deg)",
                  position: "relative",
                  top: "-125px",
                }}
              >
                APPROVED <br />
                and SIGNED
              </div>
            </>
          ) : (user?.profession === "Buyer" &&
              document.isApprovedByBuyer === true) ||
            (user?.profession === "Seller" &&
              document.isApprovedBySeller === true) ||
            (representativeRoleInContractProgress === "BuyerRepresentative" &&
              document.isApprovedByBuyerRepresentative === true) ||
            (representativeRoleInContractProgress === "SellerRepresentative" &&
              document.isApprovedBySellerRepresentative === true) ||
            (representativeRoleInContractProgress === "BuyerAttorney" &&
              document.isApprovedByBuyerAttorney === true) ||
            (representativeRoleInContractProgress === "SellerAttorney" &&
              document.isApprovedBySellerAttorney === true) ||
            (user?.profession === "Lender" &&
              document.isApprovedByLender === true) ||
            (user?.profession === "TitleAndSettlement" &&
              document.isApprovedBySettlementAgent === true) ||
            (user?.profession === "PropertyInsurance" &&
              document.isApprovedByPropertyInsurance === true) ? (
            <div
              style={{
                fontSize: "30px",
                fontWeight: "800",
                color: "green",
                border: "1px solid black",
                transform: "translateY(-20px) translateX(5px) rotate(27deg)",
                position: "relative",
                top: "-95px",
              }}
            >
              APPROVED
            </div>
          ) : null}
        </Paper>
      )}
    </div>
  );
}

// return (
//   <div
//     onDoubleClick={() => onDocumentSelect(document.id)}
//     onClick={() => onSelectDocumentOneClick(document.id)}
//   >
//     <Typography
//       variant="h6"
//       gutterBottom
//       sx={{
//         textOverflow: "ellipsis",
//         overflow: "hidden",
//         width: "200px",
//         whiteSpace: "nowrap",
//       }}
//     >
//       {document.title}
//     </Typography>
//     {document.id === selectDocumentWithIdOneClick ? (
//       <Paper
//         elevation={4}
//         sx={{
//           width: 200,
//           height: 260,
//           paddingX: 2.5,
//           paddingY: 3,
//           fontSize: "4px",
//           border: "2px solid red",
//         }}
//       >
//         <Box sx={{ wordWrap: "break-word", overflow: "hidden", height: 212 }}>
//           {ReactHtmlParser(document.text)}
//         </Box>
//         {document.isSigned === true ? (
//           <>
//             <div
//               style={{
//                 fontSize: "25px",
//                 fontWeight: "800",
//                 color: "green",
//                 border: "1px solid black",
//                 transform: "translateY(-20px) translateX(5px) rotate(27deg)",
//                 position: "relative",
//                 top: "-125px",
//               }}
//             >
//               APPROVED <br />
//               and SIGNED
//             </div>
//           </>
//         ) : document.isApproved === true ? (
//           <div
//             style={{
//               fontSize: "30px",
//               fontWeight: "800",
//               color: "green",
//               border: "1px solid black",
//               transform: "translateY(-20px) translateX(5px) rotate(27deg)",
//               position: "relative",
//               top: "-95px",
//             }}
//           >
//             APPROVED
//           </div>
//         ) : null}
//       </Paper>
//     ) : (
//       <Paper
//         elevation={4}
//         sx={{
//           width: 200,
//           height: 260,
//           paddingX: 2.5,
//           paddingY: 3,
//           fontSize: "4px",
//         }}
//       >
//         <Box sx={{ wordWrap: "break-word", overflow: "hidden", height: 212 }}>
//           {ReactHtmlParser(document.text)}
//         </Box>
//         {document.isSigned === true ? (
//           <>
//             <div
//               style={{
//                 fontSize: "25px",
//                 fontWeight: "800",
//                 color: "green",
//                 border: "1px solid black",
//                 transform: "translateY(-20px) translateX(5px) rotate(27deg)",
//                 position: "relative",
//                 top: "-125px",
//               }}
//             >
//               APPROVED <br />
//               and SIGNED
//             </div>
//           </>
//         ) : document.isApproved === true ? (
//           <div
//             style={{
//               fontSize: "30px",
//               fontWeight: "800",
//               color: "green",
//               border: "1px solid black",
//               transform: "translateY(-20px) translateX(5px) rotate(27deg)",
//               position: "relative",
//               top: "-95px",
//             }}
//           >
//             APPROVED
//           </div>
//         ) : null}
//       </Paper>
//     )}
//   </div>
// );
// }
