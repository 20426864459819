import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  DialogContentText,
  Select,
  MenuItem,
  Input,
  Typography,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import SecondaryButton from "./SecondaryButton";
import {Form} from "react-router-dom";
import mammoth from "mammoth";
import agent from "../api/agent";
import {toast} from "react-toastify";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalState: boolean;
  documentsMVPSelectList: any;
  setDocumentFamilyId: React.Dispatch<
    React.SetStateAction<{
      documentId: number;
      file: string;
      documentName: string;
    }>
  >;
  handleSubmitData(): Promise<void>;
  contractProgressId: number;
}

const DocumentSelectModal = ({
  documentsMVPSelectList,
  modalState,
  setModalOpen,
  setDocumentFamilyId,
  handleSubmitData,
  contractProgressId,
}: Props) => {
  const {handleSubmit} = useForm();
  const [childModalDocumentConfirmOpen, setChildModalDocumentConfirmOpen] =
    useState(false);
  const [currentPhase, setCurrentPhase] = useState();

  useEffect(() => {
    contractProgressId &&
      agent.ContractProgressDeal.getCurrentPhase(contractProgressId).then(
        (response) => {
          setCurrentPhase(response.currentPhaseId);
        }
      );
  }, [contractProgressId]);

  const docNames =
    documentsMVPSelectList &&
    documentsMVPSelectList.filter((item: any) => item.phaseId === currentPhase);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleChangeDocumentId = (e: any) => {
    let {value} = e.target;

    setDocumentFamilyId((prevState: any) => {
      return {
        documentName: prevState.documentName,
        documentId: value === 0 ? 1 : value,
        file: prevState.file,
      };
    });
  };

  const documentUploadHandler = async (e: any) => {
    const file = e.target.files?.[0];

    const fileTitle = file?.name.split(".")[0];

    if (file) {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (fileExtension !== "docx") {
        toast.error("Only .docx files are allowed!");
        e.target.value = ""; // Clear the input
        return;
      }
      try {
        // Convert to document to HTML
        const arrayBuffer = await file.arrayBuffer();
        const conversionResult = await mammoth.convertToHtml({arrayBuffer});

        setDocumentFamilyId((prevState) => {
          return {
            documentName: fileTitle!,
            documentId: prevState.documentId,
            file: conversionResult.value,
          };
        });
      } catch {}
    }
  };

  const Transition2 = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const ChildModalDocumentConfirm = (props: any) => {
    const handleCloseDocConfirm = (e: any) => {
      e.preventDefault();
      setChildModalDocumentConfirmOpen(false);
    };

    return (
      <div>
        <Dialog
          open={childModalDocumentConfirmOpen}
          TransitionComponent={Transition2}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{color: "rgba(66, 66, 66, 1)"}}
            >
              Are you sure you want to upload the document?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Form onClick={() => handleSubmitData()}>
              <Button
                sx={{
                  background: "#047782",
                  "&:hover": {
                    background: "#0196A4",
                  },
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                  color: "#fff",
                  borderRadius: "4px",
                  letterSpacing: "1.25px",
                  margin: "15px",
                }}
                onClick={handleCloseDocConfirm}
              >
                SAVE
              </Button>
            </Form>
            <Button
              sx={{
                background: "#fff",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#047782",
                letterSpacing: "1.25px",
                border: "1px solid #047782",
                borderRadius: "4px",
                margin: "15px",
              }}
              onClick={handleCloseDocConfirm}
            >
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <Dialog
      open={modalState}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xl"
    >
      <DialogTitle>
        <Typography sx={{fontWeight: 600, fontSize: "20px"}}>
          Select the correct Document Folder &
        </Typography>
        <Typography sx={{fontWeight: 600, fontSize: "20px"}}>
          Select a Document you want to upload
        </Typography>
        <Typography sx={{fontWeight: 400, fontSize: "14px"}}>
          You may only upload DOCX files. Please ensure your file is in this
          format.
        </Typography>
        <Typography sx={{fontWeight: 400, fontSize: "14px"}}>
          If you wish to upload Images or PDF files to be approved or signed,{" "}
          <br />
          please import them into a DOCX file and upload them here.
        </Typography>
      </DialogTitle>
      <Form onSubmit={handleSubmit(handleSubmitData)}>
        <DialogContent>
          <Grid item xs={12}>
            <Select
              sx={{minWidth: "400px", marginBottom: "5px"}}
              labelId="doc-type-label"
              id="doc-type-select"
              onChange={handleChangeDocumentId}
            >
              {docNames &&
                docNames.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.docTypeName}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Input
              id="file"
              type="file"
              onChange={documentUploadHandler}
              fullWidth
              inputProps={{accept: ".docx"}}
            />
          </Grid>
        </DialogContent>
      </Form>
      <DialogActions>
        <ChildModalDocumentConfirm
          setModalOpen={setChildModalDocumentConfirmOpen}
          modalState={childModalDocumentConfirmOpen}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            setChildModalDocumentConfirmOpen(true);
          }}
          sx={{
            background: "rgba(4, 119, 130, 1)",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "rgba(1, 150, 164, 1)",
            },
            "&:disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: "500",
            color: "rgba(255, 255, 255, 1)",
            borderRadius: "4px",
            letterSpacing: "1.25px",
            textAlign: "center",
            lineHeight: "36px",
            minWidth: "100px",
            height: "40px",
            margin: "15px",
          }}
        >
          Submit
        </Button>
        <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentSelectModal;
