import {
  Box,
  Card,
  CardActions,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PrimaryButton from "../../app/components/PrimaryButton";
import SecondaryButton from "../../app/components/SecondaryButton";
import {useNavigate, useParams} from "react-router-dom";
import agent from "../../app/api/agent";
import {useAppDispatch, useAppSelector} from "../../app/store/configureStore";
import {LenderOffer} from "../../app/models/lenderOffer";
import RfpAdditionalDocumentsListCard from "../../app/components/RfpAdditionalDocumentsListCard";
import {Property} from "../../app/models/property";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  openCreateChat,
  openMessageCentar,
} from "../messageCenter/messageCenterSlice";
import {toast} from "react-toastify";

export default function LenderOfferViewPageMarketplace() {
  const {user} = useAppSelector((state) => state.account);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {id} = useParams<{id: string}>();
  const [lenderOffer, setLenderOffer] = useState<LenderOffer>();
  const [lenderOfferDoc, setLenderOfferDoc] = useState([]);
  const [property, setProperty] = useState<Property>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      setLoading(true);
      agent.LenderOffers.oneLenderOffer(parseInt(id))
        .then((response) => {
          setLenderOffer(response);
          setLenderOfferDoc(response.documents.$values);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [id]);

  const handleInquiry = () => {
    dispatch(openMessageCentar());
    dispatch(openCreateChat());

    toast.warning(
      "Create new chat to Inquire. Use the Email Address of the User"
    );
  };

  useEffect(() => {
    if (lenderOffer?.propertyId) {
      setLoading(true);
      agent.Marketplace.details(lenderOffer?.propertyId)
        .then((response) => {
          setProperty(response);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [lenderOffer?.propertyId]);

  function handleCancel() {
    navigate(-1);
  }

  if (!user) {
    navigate("/login");
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <Box
      sx={{
        backgroundColor: "#F4F4F4",
        height: "100%",
        width: "100%",
        padding: "20px",
        paddingTop: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: "1",
          width: "100%",
          alignItems: "center",
          position: "sticky",
          backgroundColor: "#F5F5F5",
          top: 0,
          height: "60px",
          zIndex: "10",
        }}
      >
        <ArrowBackIosNewIcon
          onClick={handleCancel}
          sx={{
            display: "flex",
            color: "rgba(112, 112, 112, 1)",
            "&:hover": {
              color: "rgba(208, 235, 238, 1)",
            },
          }}
        />
      </Box>

      <Card
        sx={{
          padding: "35px",
          height: "100%",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: "600",
            fontSize: "23px",
            lineHeight: "32px",
            marginBottom: "15px",
          }}
        >
          Lender Offer
        </Typography>

        <Grid
          container
          spacing={3}
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            width: "50%",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Name of Lender"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="nameOfLender"
              value={lenderOffer?.nameOfLender}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Name of Originator"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="nameOfOriginator"
              value={lenderOffer?.nameOfOriginator}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Branch Address"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="branchAddress"
              value={lenderOffer?.branchAddress}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Branch Phone Number"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="branchPhoneNumber"
              value={lenderOffer?.branchPhoneNumber}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Contact Number"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="contactNumber"
              value={lenderOffer?.contactNumber}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="EmailAddress"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="emailAddress"
              value={lenderOffer?.emailAddress}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Mortgage Rate %"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="mortgageRate"
              value={lenderOffer?.mortgageRate}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="APR Annual Percentage Rate %"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="annualPercentageRate"
              value={lenderOffer?.annualPercentageRate}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Loan Amount"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="loanAmount"
              value={lenderOffer?.loanAmount}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Loan Term"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="loanTerm"
              value={lenderOffer?.loanTerm}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Percent Down $"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="percentDown"
              value={lenderOffer?.percentDown}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Closing Cost $"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="closingCost"
              value={lenderOffer?.closingCost}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Primary Use"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="primaryUse"
              value={lenderOffer?.primaryUse}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Points in"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="pointsIn"
              value={lenderOffer?.pointsIn}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            width: "50%",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          <Grid item xs={6}>
            <FormGroup sx={{display: "flex", flexDirection: "row"}}>
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    "&.Mui-disabled": {
                      color: "black",
                    },
                  },
                }}
                disabled
                control={
                  <Checkbox
                    checked={lenderOffer?.optionToPrePay}
                    sx={{
                      "&.Mui-disabled": {
                        color: "#000000",
                      },
                    }}
                  />
                }
                label="Option to pre-pay"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup sx={{display: "flex", flexDirection: "row"}}>
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    "&.Mui-disabled": {
                      color: "black",
                    },
                  },
                }}
                disabled
                control={
                  <Checkbox
                    checked={lenderOffer?.optionToRefinance}
                    sx={{
                      "&.Mui-disabled": {
                        color: "#000000",
                      },
                    }}
                  />
                }
                label="Option to refinance"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="standard"
              disabled
              label="Message to Buyer"
              InputLabelProps={{
                style: {color: "#000000"},
              }}
              type="text"
              name="messageToBuyer"
              value={lenderOffer?.messageToBuyer}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>

          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "32px",
              marginTop: "156px",
              width: "100%",
            }}
          >
            Offer letter / term sheet
          </Typography>
          {lenderOfferDoc && lenderOfferDoc.length > 0 ? (
            <Card sx={{backgroundColor: "#F7F7F7", padding: 1}}>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RfpAdditionalDocumentsListCard
                    rfpDocuments={lenderOfferDoc}
                    name="x"
                  ></RfpAdditionalDocumentsListCard>
                </CardActions>
              </CardActions>
            </Card>
          ) : (
            <Card
              sx={{
                backgroundColor: "#F7F7F7",
                padding: 1,
                height: "300px",
                width: "470px",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "flex-start",
                flexWrap: "wrap",
              }}
            >
              No documents attached
            </Card>
          )}
        </Grid>
      </Card>

      {user?.id === property?.userId ? null : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            bottom: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "rgba(253, 237, 237, 1)",
              margin: "15px",
              paddingLeft: "10px",
              paddingRight: "10px",
              position: "relative",
              right: "250px",
            }}
          >
            <ErrorOutlineIcon sx={{color: "rgba(255, 82, 82, 1)"}} />
            <Typography
              sx={{
                color: "rgba(95, 33, 32, 0.87)",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0.5px",
              }}
            >
              Lender offers can only be accepted during the “Prepare Offer”
              process.
            </Typography>
          </Box>
          <PrimaryButton disabled>Accept Offer</PrimaryButton>
          <SecondaryButton onClick={() => handleInquiry()}>
            Inquire
          </SecondaryButton>
        </Box>
      )}
    </Box>
  );
}
