import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Card, Checkbox, Chip} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import agent from "../../../app/api/agent";
import LoaderWithLinearProgress from "../../../app/components/LoaderWithLinearProgress";
import {useAppSelector} from "../../../app/store/configureStore";
import LinkIcon from "@mui/icons-material/Link";
import {toast} from "react-toastify";

interface Props {
  dealId: number;
}

const OffersTable = ({dealId}: Props) => {
  const {user} = useAppSelector((state) => state.account);
  const [property, setProperty] = useState<any>();
  const [offers, setOffers] = useState<Array<any>>([]);
  const [myOffers, setMyOffers] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleOffer = (offerId: any) => {
    navigate(`/offer/propertyfilled/${offerId}/${dealId}`);
  };
  useEffect(() => {
    agent.Deals.oneDealProperty(dealId)
      .then((response) => {
        setProperty(response);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  }, [dealId]);

  useEffect(() => {
    agent.OfferProperty.AllPropertyOffers(dealId)
      .then((response) => {
        setOffers(response.$values);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  }, [dealId]);

  useEffect(() => {
    agent.OfferProperty.UserOffersForProperty(dealId)
      .then((response) => {
        setMyOffers(response.$values);
      })
      .catch((error) => console.log(error.response))
      .finally(() => setLoading(false));
  }, [dealId]);

  function handleShareProperty(url: any, e: any) {
    e.preventDefault();
    let currentURL = window.location.hostname;

    var withoutProtocol = currentURL.replace(/^https?:\/\//, "");

    // Remove path and query string
    var domain = withoutProtocol.split("/")[0];

    // Remove port number if present
    domain = domain.split(":")[0];

    let fullAddress = domain.concat(url);

    navigator.clipboard.writeText(fullAddress).then(
      () => {
        toast.success("The offer link is copied!");
      },
      () => {
        toast.error("Failed to copy. Please try again later.");
      }
    );
  }

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <Card sx={{height: "calc(100% - 54.5px)", overflow: "scroll"}}>
      {offers && offers.length > 0 ? (
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Card
            sx={{
              display: "inline-flex",
              width: "100%",
              backgroundColor: "#FFF",
              border: "none",
              boxShadow: "none",
            }}
          >
            <Table sx={{minWidth: 650}}>
              <TableHead>
                <TableRow>
                  <div>
                    <TableCell width="5.6%">
                      <Checkbox />
                    </TableCell>
                    <TableCell width="11.8%">
                      <ArrowUpwardIcon /> Name
                    </TableCell>
                    <TableCell width="11.8%">
                      <ArrowUpwardIcon /> Price
                    </TableCell>
                    <TableCell width="11.8%"> Earnest Money</TableCell>
                    <TableCell width="11.8%"> Mortgage </TableCell>
                    <TableCell width="11.8%"> Pre-approval</TableCell>
                    <TableCell width="11.8%"> Sale Contingency</TableCell>
                    <TableCell width="11.8%"> Inspection Contingency</TableCell>
                  </div>
                  <TableCell width="11.8%"> Share Link</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Card>

          {user?.id === property?.userId
            ? offers.map((offer) => (
                <Card
                  sx={{
                    display: "inline-flex",
                    width: "100%",
                    backgroundColor: "#FFF",
                    border: "none",
                    boxShadow: "none",
                  }}
                  key={offer.id}
                >
                  <Table sx={{minWidth: 650}}>
                    <TableBody>
                      <TableRow>
                        <div onClick={() => handleOffer(offer.id)}>
                          <TableCell width="5.6%" component="th" scope="row">
                            <Checkbox />
                          </TableCell>
                          <TableCell width="11.8%">
                            {offer.nameOfBuyer}
                          </TableCell>
                          <TableCell width="11.8%">
                            ${offer.offerPrice}
                          </TableCell>
                          <TableCell width="11.8%">
                            {offer.earnestMoneyAmountAndPaid}
                          </TableCell>
                          <TableCell width="11.8%"> {offer.mortgage}</TableCell>
                          <TableCell width="11.8%">
                            {offer.preApproval === "Yes" ? (
                              <Chip
                                label="Yes"
                                color="success"
                                variant="filled"
                              />
                            ) : (
                              <Chip label="No" color="error" variant="filled" />
                            )}
                          </TableCell>
                          <TableCell width="11.8%">
                            {offer.landUseApproval === true ? (
                              <Chip
                                label="Yes"
                                color="success"
                                variant="filled"
                              />
                            ) : (
                              <Chip label="No" color="error" variant="filled" />
                            )}
                          </TableCell>
                          <TableCell width="11.8%">
                            {offer.insuranceApproval === true ? (
                              <Chip
                                label="Yes"
                                color="success"
                                variant="filled"
                              />
                            ) : (
                              <Chip label="No" color="error" variant="filled" />
                            )}
                          </TableCell>
                        </div>
                        <TableCell width="11.8%">
                          <LinkIcon
                            onClick={(e) =>
                              handleShareProperty(
                                `/offer/propertyfilled/${offer.id}/${dealId}`,
                                e
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              ))
            : myOffers.map((offer) => (
                <Card
                  sx={{
                    display: "inline-flex",
                    width: "100%",
                    backgroundColor: "#FFF",
                    border: "none",
                    boxShadow: "none",
                  }}
                  key={offer.id}
                >
                  <Table sx={{minWidth: 650}}>
                    <TableBody>
                      <TableRow onClick={() => handleOffer(offer.id)}>
                        <TableCell width="5.6%" component="th" scope="row">
                          <Checkbox />
                        </TableCell>
                        <TableCell width="11.8%">{offer.nameOfBuyer}</TableCell>
                        <TableCell width="11.8%">${offer.offerPrice}</TableCell>
                        <TableCell width="11.8%">
                          {offer.earnestMoneyAmountAndPaid}
                        </TableCell>
                        <TableCell width="11.8%"> {offer.mortgage}</TableCell>
                        <TableCell width="11.8%">
                          {offer.preApproval === "Yes" ? (
                            <Chip
                              label="Yes"
                              color="success"
                              variant="filled"
                            />
                          ) : (
                            <Chip label="No" color="error" variant="filled" />
                          )}
                        </TableCell>
                        <TableCell width="11.8%">
                          {offer.landUseApproval === true ? (
                            <Chip
                              label="Yes"
                              color="success"
                              variant="filled"
                            />
                          ) : (
                            <Chip label="No" color="error" variant="filled" />
                          )}
                        </TableCell>
                        <TableCell width="11.8%">
                          {offer.insuranceApproval === true ? (
                            <Chip
                              label="Yes"
                              color="success"
                              variant="filled"
                            />
                          ) : (
                            <Chip label="No" color="error" variant="filled" />
                          )}
                        </TableCell>
                        <TableCell width="11.8%">
                          <LinkIcon
                            onClick={(e) =>
                              handleShareProperty(
                                `/offer/propertyfilled/${offer.id}/${dealId}`,
                                e
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              ))}
        </Box>
      ) : (
        <Card
          sx={{
            boxShadow: "none",
            backgroundColor: "#FFF",
            width: "100%",
            padding: "10px",
            marginLeft: "10px",
          }}
        >
          There are no offers
        </Card>
      )}
    </Card>
  );
};

export default OffersTable;
