import {Box, Typography} from "@mui/material";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import AlarmIcon from "@mui/icons-material/Alarm";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import {useEffect, useRef, useState} from "react";
import LightTooltip from "../../../app/components/LightTooltip";
import agent from "../../../app/api/agent";
import LoaderWithLinearProgress from "../../../app/components/LoaderWithLinearProgress";

interface Props {
  dealId: number;
  onActionOverviewClick: () => void;
  property: any;
  propertyStatus: any;
  setPropertyStatus: React.Dispatch<any>;
}

export default function CardActionsOverview({
  dealId,
  onActionOverviewClick,
  property,
  propertyStatus,
  setPropertyStatus,
}: Props) {
  const [activeIcon, setActiveIcon] = useState(0);
  const timer = useRef<NodeJS.Timeout>();
  const [highestTaskMessageId, setHighestTaskMessageId] = useState<number>();
  const [loading, setLoading] = useState(false);

  const ChangePropertyStatus = (id: any) => {
    clearTimeout(timer.current);

    setActiveIcon(id);
    timer.current = setTimeout(() => setActiveIcon(0), 3000);
  };

  function formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", {month: "short"});
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  // Function to add days to a Date object
  function addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // Assuming `property` is an object passed as a prop or available in scope
  const statusDateTimeString = property.statusDateTime;

  // Default date string
  const defaultDateString = "Due: MMM/DD/YYYY";

  // Calculate the display date
  let displayDate: string;
  if (statusDateTimeString === "0001-01-01T00:00:00") {
    displayDate = defaultDateString;
  } else {
    // Parse the date string to a Date object
    const statusDateTime = new Date(statusDateTimeString);

    // Add two days
    const newDate = addDays(statusDateTime, 2);

    // Format the new date
    displayDate = formatDate(newDate);
  }

  const today = new Date();
  const displayDateObject = new Date(
    displayDate.split("/").reverse().join("/")
  );
  const isPastDue = displayDateObject < today;

  const icons: any = [
    {
      label: "Coming up",
      //icon: <AlarmOnIcon sx={{color: "#FFC107"}} />,
      icon: isPastDue ? (
        <AlarmOffIcon sx={{color: "#ff0707"}} />
      ) : (
        <AlarmOnIcon sx={{color: "#FFC107"}} />
      ),
      title:
        property.status === null
          ? "There are no Tasks available at the moment"
          : property.status,
    },
    {
      label: "On time",
      icon: <AlarmIcon sx={{color: "#4CAF50"}} />,
      title:
        property.statusPlusTwo === null
          ? "There are no Tasks available at the moment"
          : property.statusPlusTwo,
    },
    {
      label: "Later on",
      icon: <AddAlarmIcon sx={{color: "#707070"}} />,
      title:
        property.statusPlusThree === null
          ? "There are no Tasks available at the moment"
          : property.statusPlusThree,
    },
  ];

  useEffect(() => {
    if (property.contractProgressId) setLoading(true);
    agent.ContractProgressDeal.getmvptaskmessagechecker(
      property.contractProgressId
    ).then((response) => {
      const mvpTaskMessageIds = response.$values.map(
        (item: any) => item.mvpTaskMessageId
      );

      const maxMvpTaskMessageId = Math.max(...mvpTaskMessageIds);

      setHighestTaskMessageId(maxMvpTaskMessageId);
      setLoading(false);
    });
  }, [property.contractProgressId]);

  useEffect(() => {
    setPropertyStatus(property.status);
  }, [property.status, setPropertyStatus]);

  return (
    <>
      <Box
        onClick={onActionOverviewClick}
        sx={{width: "15%", display: "flex", justifyContent: "right"}}
      >
        <Typography sx={{marginRight: "10px", svg: {fontSize: "50px"}}}>
          {icons[activeIcon].icon}
        </Typography>
      </Box>
      <Box
        onClick={onActionOverviewClick}
        sx={{
          width: "35%",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              color: "#424242",
              font: "Roboto",
              fontWeight: "400",
              lineHeight: "32px",
            }}
          >
            {loading ? (
              <LoaderWithLinearProgress />
            ) : activeIcon === 1 && property.statusPlusTwo != null ? (
              property.statusPlusTwo
            ) : activeIcon === 1 && property.statusPlusTwo === null ? (
              "There are no Tasks available at the moment"
            ) : activeIcon === 2 && property.statusPlusThree != null ? (
              property.statusPlusThree
            ) : activeIcon === 2 && property.statusPlusThree === null ? (
              "There are no Tasks available at the moment"
            ) : highestTaskMessageId! === -Infinity ? (
              propertyStatus
            ) : property.status === "No more Tasks to Complete" ? (
              property.status
            ) : highestTaskMessageId! + 1 === property.statusId ? (
              propertyStatus
            ) : highestTaskMessageId === 31 ? (
              "Congratulations - Your Transaction is Complete!"
            ) : highestTaskMessageId !== property.statusId ? (
              "Please wait for other users to complete their task(s)"
            ) : null}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#757575",
              font: "Roboto",
              fontWeight: "400",
              lineHeight: "20px",
              fontSize: "14px",
            }}
          >
            {highestTaskMessageId &&
            highestTaskMessageId + 1 === property.statusId
              ? "Due: " + displayDate
              : property.statusDateTime === "0001-01-01T00:00:00"
              ? defaultDateString
              : defaultDateString}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "10%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "end",
          marginRight: "5px",
        }}
      >
        {icons.map(({icon, label, title}: any, i: number) => {
          return (
            <LightTooltip
              title={`${label}: ${title} `}
              placement="left"
              key={`${dealId}-${i}`}
            >
              <Box
                component="span"
                sx={{display: "flex", margin: "5px"}}
                onClick={() => ChangePropertyStatus(i)}
              >
                {icon}
              </Box>
            </LightTooltip>
          );
        })}
      </Box>
    </>
  );
}
