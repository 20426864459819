import {
  Box,
  Card,
  CardActions,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import LoaderWithLinearProgress from "../../app/components/LoaderWithLinearProgress";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AppTextInput from "../../app/components/AppTextInputCustom";
import {Controller, FieldValues, useForm} from "react-hook-form";
import PrimaryButton from "../../app/components/PrimaryButton";
import SecondaryButton from "../../app/components/SecondaryButton";
import ReusableDropzoneMultipleImages from "../../app/components/ReusableDropzoneMultipleImages";
import {Form, useNavigate, useParams} from "react-router-dom";
import agent from "../../app/api/agent";
import {toast} from "react-toastify";
import {useAppSelector} from "../../app/store/configureStore";

export default function CreateLenderOffer() {
  const [loading, setLoading] = useState(false);
  const {user} = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const {control, handleSubmit} = useForm();
  const {id} = useParams<{id: string}>();
  const [property, setProperty] = useState({
    id: 0,
  });

  const dzStyles = {
    display: "flex",
    paddingTop: "30px",
    width: 530,
    height: 300,
    justifyContent: "center",
  };

  async function handleSubmitData(data: FieldValues) {
    setLoading(true);
    data.PropertyId = property.id;
    try {
      agent.LenderOffers.createLenderOffer(data).then((response) => {
        toast.success("Offer was sent successfully");
        navigate("/marketplace");
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    navigate(-1);
  }

  useEffect(() => {
    if (id) {
      agent.Marketplace.details(parseInt(id))
        .then((response) => {
          setProperty(response);
        })
        .catch((error) => console.log(error.response))
        .finally(() => setLoading(false));
    }
  }, [id]);

  if (loading) return <LoaderWithLinearProgress />;

  return (
    <Box
      sx={{
        backgroundColor: "#F4F4F4",
        height: "100%",
        width: "100%",
        padding: "20px",
        paddingTop: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: "1",
          width: "100%",
          alignItems: "center",
          position: "sticky",
          backgroundColor: "#F5F5F5",
          top: 0,
          height: "60px",
          zIndex: "10",
        }}
        onClick={handleCancel}
      >
        <ArrowBackIosNewIcon
          sx={{
            display: "flex",
            color: "rgba(112, 112, 112, 1)",
            "&:hover": {
              color: "rgba(208, 235, 238, 1)",
            },
          }}
        />
      </Box>
      <Form onSubmit={handleSubmit(handleSubmitData)}>
        <Card
          sx={{
            padding: "35px",
            height: "100%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "600",
              fontSize: "23px",
              lineHeight: "32px",
              marginBottom: "15px",
            }}
          >
            Lender Offer
          </Typography>

          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            <Grid item xs={6} sx={{display: "none"}}>
              <AppTextInput
                control={control}
                label="PropertyId"
                name="PropertyId"
                value={property.id}
              />
            </Grid>

            <Grid item xs={12}>
              <AppTextInput
                control={control}
                label="Name of Lender"
                type="text"
                name="nameOfLender"
                required
                defaultValue={user?.firstName}
              />
            </Grid>

            <Grid item xs={12}>
              <AppTextInput
                control={control}
                label="Name of Originator"
                type="text"
                name="nameOfOriginator"
              />
            </Grid>

            <Grid item xs={12}>
              <AppTextInput
                control={control}
                label="Branch Address"
                type="text"
                name="branchAddress"
                required
                defaultValue={user?.companyAddress}
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Branch Phone Number"
                type="text"
                name="branchPhoneNumber"
                defaultValue={user?.phoneNumber}
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Contact Number"
                type="text"
                name="contactNumber"
                defaultValue={user?.phoneNumber}
              />
            </Grid>

            <Grid item xs={12}>
              <AppTextInput
                control={control}
                label="EmailAddress"
                type="text"
                name="emailAddress"
                required
                defaultValue={user?.email}
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Mortgage Rate %"
                type="number"
                name="mortgageRate"
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="APR Annual Percentage Rate %"
                type="number"
                name="annualPercentageRate"
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Loan Amount"
                type="number"
                name="loanAmount"
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Loan Term"
                type="text"
                name="loanTerm"
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Percent Down $"
                type="number"
                name="percentDown"
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Closing Cost $"
                type="number"
                name="closingCost"
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Primary Use"
                type="text"
                name="primaryUse"
              />
            </Grid>

            <Grid item xs={6}>
              <AppTextInput
                control={control}
                label="Points in"
                type="text"
                name="pointsIn"
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            sx={{
              display: "inline-flex",
              flexDirection: "row",
              width: "50%",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            <Grid item xs={6}>
              <FormGroup sx={{display: "flex", flexDirection: "row"}}>
                <Box>
                  <Controller
                    control={control}
                    name="optionToPrePay"
                    defaultValue={false}
                    render={({field: {onChange, value}}) => (
                      <FormControlLabel
                        control={
                          <Checkbox checked={value} onChange={onChange} />
                        }
                        label="Option to pre-pay"
                      />
                    )}
                  />
                </Box>
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup sx={{display: "flex", flexDirection: "row"}}>
                <Box>
                  <Controller
                    control={control}
                    name="optionToRefinance"
                    defaultValue={false}
                    render={({field: {onChange, value}}) => (
                      <FormControlLabel
                        control={
                          <Checkbox checked={value} onChange={onChange} />
                        }
                        label="Option to refinance"
                      />
                    )}
                  />
                </Box>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <AppTextInput
                control={control}
                label="Message to Buyer"
                type="text"
                name="messageToBuyer"
              />
            </Grid>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "32px",
                marginTop: "156px",
                width: "100%",
              }}
            >
              Upload offer letter / term sheet
            </Typography>
            <Card sx={{backgroundColor: "#F7F7F7", padding: 1}}>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl style={dzStyles}>
                  <ReusableDropzoneMultipleImages
                    control={control}
                    name="offerLetter"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      alignItems="center"
                    >
                      <img
                        src={"/images/DropzoneIcon.png"}
                        alt="IMG"
                        style={{
                          height: "125px",
                          width: "125px",
                        }}
                      />
                      <Typography variant="subtitle1">
                        Drag and drop a photo here to upload
                        <Typography
                          sx={{
                            textDecoration: "underline",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          or click here to browse
                        </Typography>
                      </Typography>
                    </Box>
                  </ReusableDropzoneMultipleImages>
                </FormControl>
              </CardActions>
            </Card>
          </Grid>
        </Card>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            position: "sticky",
            backgroundColor: "#F5F5F5",
            bottom: 0,
          }}
        >
          <PrimaryButton type="submit">Submit</PrimaryButton>
          <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
        </Box>
      </Form>
    </Box>
  );
}
